import React from "react";
import "./CVcategories.css"

const CVcatPub: React.FC = () => {


    return (
        <div className='CVcategories-container'>
            <div className='cvitem-cat'>
                Publications
            </div>
            <div className='projects'>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2025
                    </div>
                    <div className='cvitem-info'>
                        “Programmed Hope” (article c/o Rafael Amato) <span className="oblique">HfG Archiv, Museum Ulm</span>
                    </div>
                    
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2022
                    </div>
                    <div className='cvitem-info'>
                        “Messy History Report #2” <span className="oblique">Messy Archive Group</span>
                    </div>
                    
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2021
                    </div>
                    <div className='cvitem-info'>
                        “Glossary of Undisciplined Design” (book contribution) <span className="oblique">Spector Books</span>
                    </div>
                    
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2020
                    </div>
                    <div className='cvitem-info'>
                        “Messy History Report #1” <span className="oblique">Messy Archive Group</span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CVcatPub