import React from "react";
import "./CVcategories.css"

const CVcatGrant: React.FC = () => {


    return (
        <div className='CVcategories-container'>
            <div className='cvitem-cat'>
                Residencies
            </div>
            <div className='projects'>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2024
                    </div>
                    <div className='cvitem-info'>
                        Bauhaus Lab "On Behalf of the Environment. Pedagogies of Unrest"<span className="oblique">Bauhaus Dessau</span>
                    </div>
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2022
                    </div>
                    <div className='cvitem-info'>
                        Residency with Messy Archive Group <span className="oblique">atelier automatique, Bochum</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CVcatGrant