import "./imagesGallery.css"
import React, {useRef, useState} from "react";

// export interface ImagesGalleryProps {
//     setShowImages: React.Dispatch<React.SetStateAction<boolean>>;
// }



export interface ImagesGalleryProps {
    setShowImages: React.Dispatch<React.SetStateAction<boolean>>;
}


const ImagesGallery: React.FC<ImagesGalleryProps> = ({setShowImages}) => {
    const imageInfo = [
        {
            path: 'BauhausLab_2024BauhausDessau_1.jpg',
            text: 'Bauhaus Lab 2024, 2024\n©Bauhaus Dessau',
        },
        {
            path: 'BauhausLab_2024BauhausDessau_2.jpg',
            text: 'Bauhaus Lab 2024, 2024\n©Bauhaus Dessau',
        },
        {
            path: 'BauhausLab_2024BauhausDessau_3.jpg',
            text: 'Bauhaus Lab 2024, 2024\n©Bauhaus Dessau',
        },
        {
            path: 'BauhausLab_2024BauhausDessau_5.jpg',
            text: 'Bauhaus Lab 2024, 2024\n©Bauhaus Dessau',
        },
        {
            path: 'BauhausLab_2024BauhausDessau_4.jpg',
            text: 'Bauhaus Lab 2024, 2024\n©Bauhaus Dessau',
        },
        {
            path: 'GrelleTage_p_l_zzo_1.jpg',
            text: 'Grelle Tage, Schauspielhaus Bochum, 2024\n©p_l_zzo photography',
        },
        {
            path: 'GrelleTage_p_l_zzo_2.jpg',
            text: 'Grelle Tage, Schauspielhaus Bochum, 2024\n©p_l_zzo photography',
        },
        {
            path: 'GrelleTage_p_l_zzo_3.jpg',
            text: 'Grelle Tage, Schauspielhaus Bochum, 2024\n©p_l_zzo photography',
        },
        {
            path: 'GrelleTage_p_l_zzo_5.jpg',
            text: 'Grelle Tage, Schauspielhaus Bochum, 2024\n©p_l_zzo photography',
        },
        {
            path: 'GrelleTage_p_l_zzo_6.jpg',
            text: 'Grelle Tage, Schauspielhaus Bochum, 2024\n©p_l_zzo photography',
        },
        {
            path: 'GrelleTage_p_l_zzo_7.jpg',
            text: 'Grelle Tage, Schauspielhaus Bochum, 2024\n©p_l_zzo photography',
        },
        {
            path: 'DisparatePrecedentsOfDisplay_2023LizzyEllbruck_1.jpg',
            text: 'Disparate Precedents of Display, 2023\n©Lizzy Ellbrück',
        },
        {
            path: 'DisparatePrecedentsOfDisplay_2023LizzyEllbruck_9.jpg',
            text: 'Disparate Precedents of Display, 2023\n©Lizzy Ellbrück',
        },
        {
            path: 'DisparatePrecedentsOfDisplay_2023LizzyEllbruck_2.jpg',
            text: 'Disparate Precedents of Display, 2023\n©Lizzy Ellbrück',
        }, {
            path: 'DisparatePrecedentsOfDisplay_2023LizzyEllbruck_3.jpg',
            text: 'Disparate Precedents of Display, 2023\n©Lizzy Ellbrück',
        },
        {
            path: 'DisparatePrecedentsOfDisplay_2023LizzyEllbruck_5.jpg',
            text: 'Disparate Precedents of Display, 2023\n©Lizzy Ellbrück',
        },
        {
            path: 'DisparatePrecedentsOfDisplay_2023LizzyEllbruck_4.jpg',
            text: 'Disparate Precedents of Display, 2023\n©Lizzy Ellbrück',
        },
        {
            path: 'DisparatePrecedentsOfDisplay_2023LizzyEllbruck_7.jpg',
            text: 'Disparate Precedents of Display, 2023\n©Lizzy Ellbrück',
        }, {
            path: 'DisparatePrecedentsOfDisplay_2023LizzyEllbruck_6.jpg',
            text: 'Disparate Precedents of Display, 2023\n©Lizzy Ellbrück',
        },
         {
            path: 'DisparatePrecedentsOfDisplay_2023LizzyEllbruck_8.jpg',
            text: 'Disparate Precedents of Display, 2023\n©Lizzy Ellbrück',
        }, {
            path: 'DisparatePrecedentsOfDisplay_2023LizzyEllbruck_10.jpg',
            text: 'Disparate Precedents of Display, 2023\n©Lizzy Ellbrück',
        }, {
            path: 'lightbox.jpg',
            text: 'construction of a light box\n2022',
        }, {
            path: 'MessyArchiveGroup_2021_1.jpg',
            text: 'Messy Archive Group, intervention in public space\nBochum 2021',
        }, {
            path: 'MessyArchiveGroup_2021_2.jpg',
            text: 'Messy Archive Group, intervention in public space\nBochum 2021',
        }, {
            path: 'MessyArchiveGroup_2021_3.jpg',
            text: 'Messy Archive Group, intervention in public space\nBochum 2021',
        }, {
            path: 'MessyArchiveGroup_x_BreakingTheSpell.jpeg',
            text: 'Workshop by Messy Archive Group\nBreaking the Spell@Schauspiel Leipzig 2022',
        }, {
            path: 'MessyHistoryReport_II_1.jpg',
            text: 'Messy History Report #2\n2022',
        }, {
            path: 'MessyHistoryReport_II_2.jpg',
            text: 'Messy History Report #2\n2022',
        }, {
            path: 'PlantageDachau_2023ConstanzaMelendezToha_Carla.jpg',
            text: 'Plantage Dachau, 2023\n©Constanza Meléndez Tohá',
        }, {
            path: 'PlantageDachau_2023ConstanzaMelendezToha_display1.jpg',
            text: 'Plantage Dachau, 2023\n©Constanza Meléndez Tohá',
        }, {
            path: 'PlantageDachau_2023ConstanzaMelendezToha_display2.jpg',
            text: 'Plantage Dachau, 2023\n©Constanza Meléndez Tohá',
        }, {
            path: 'PlantageDachau_2023ConstanzaMelendezToha_asphalt.jpg',
            text: 'Plantage Dachau, 2023\n©Constanza Meléndez Tohá',
        }, {
            path: 'PlantageDachau_2023ConstanzaMelendezToha_display3.jpg',
            text: 'Plantage Dachau, 2023\n©Constanza Meléndez Tohá',
        }, {
            path: 'PlantageDachau_2023ConstanzaMelendezToha_Jana.jpg',
            text: 'Plantage Dachau, 2023\n©Constanza Meléndez Tohá',
        }, {
            path: 'PlantageDachau_2023ConstanzaMelendezToha_moss.jpg',
            text: 'Plantage Dachau, 2023\n©Constanza Meléndez Tohá',
        }, {
            path: 'PlantageDachau_2023ConstanzaMelendezToha_stone.jpg',
            text: 'Plantage Dachau, 2023\n©Constanza Meléndez Tohá',
        }, {
            path: 'PlantageDachau_2023ConstanzaMelendezToha_Suheyla.jpg',
            text: 'Plantage Dachau, 2023\n©Constanza Meléndez Tohá',
        }, {
            path: 'PlantageDachau_2023ConstanzaMelendezToha_Wunschelrute.jpg',
            text: 'Plantage Dachau, 2023\n©Constanza Meléndez Tohá',
        }, {
            path: 'preparations_2023_1.jpg',
            text: 'preparations for Plantage Dachau\n2023',
        }, {
            path: 'preparations_2023_2.jpg',
            text: 'preparations for Plantage Dachau\n2023',
        }
        // Add similar objects for other images
    ];

    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const handleInfoButtonClick = () => {
        setShowImages(false); // Switch to the CVItem1 component
    };

    const containerRef = useRef<HTMLDivElement>(null);

    const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += event.deltaY;
        }
    };

    return (
        <div>
            <header className="images-header">
                <div>Teresa Häußler: Scenography, Design, Research.</div>
                <button className="button-nav" onClick={handleInfoButtonClick}>
                    INFO &#62;
                </button>
            </header>
            <div className="image-container" onWheel={handleScroll} ref={containerRef}>
                {imageInfo.map((image, index) => (
                    <div className="image-wrapper"
                         key={index}
                         onMouseEnter={() => setHoveredIndex(index)}
                         onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <img
                            src={process.env.PUBLIC_URL + 'images/' + image.path}
                            alt={`number ${index + 1}`}
                            style={{height: '100%', marginRight: '0px'}}
                        />
                        {hoveredIndex === index && (
                            <div className="image-overlay">{image.text}</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImagesGallery;
